export const tableProperties = [
  {
    label: '所属部门',
    prop: 'dept_name',
    itemType: 'select',
    options: [],
    input: true,
    sortable: false,
    labelWidth: 120,
    needOtherColumn: true
  },
  {
    label: '录入人',
    prop: 'stff_name',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 120
  },
  {
    label: '一月',
    prop: 'jan',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '二月',
    prop: 'feb',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '三月',
    prop: 'mar',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '四月',
    prop: 'apr',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '五月',
    prop: 'may',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '六月',
    prop: 'june',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '七月',
    prop: 'july',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '八月',
    prop: 'aug',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '九月',
    prop: 'sept',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '十月',
    prop: 'oct',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '十一月',
    prop: 'nov',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '十二月',
    prop: 'dece',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    align: 'right'
  },
  {
    label: '合计',
    prop: 'yearTotal',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 140,
    align: 'right'
  }
];
